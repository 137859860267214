import { useState } from "react";

import {
  Text,
  Flex,
  Content,
  ProgressCircle,
  Heading,
  Divider,
  Dialog,
  IllustratedMessage,
} from "@adobe/react-spectrum";

function RealTimeCustomerProfile() {
  const [getProfil, setGetProfil] = useState(false);

  // wainting for real time customer profile supabase
  // setGetProfil(false);

  return (
    <Dialog>
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Text>Mon profil</Text>
        </Flex>
      </Heading>

      <Divider />

      <Content height="size-5000">
        {!getProfil && (
          <IllustratedMessage maxWidth="100%" height="100%">
            <ProgressCircle margin="size-100" aria-label="En cours de modération..." isIndeterminate />
            <Heading>En cours de modération...</Heading>
            <Content>
              Tramway • courant alternatif <span title="Emoji : Marque déposée"></span>®
            </Content>
          </IllustratedMessage>
        )}
      </Content>
    </Dialog>
  );
}

export default RealTimeCustomerProfile;
