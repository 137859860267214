import { Link as RouterLink } from "react-router-dom";

import { IllustratedMessage, Content, Link } from "@adobe/react-spectrum";

import Unauthorized from "@spectrum-icons/illustrations/Unauthorized";

import { PUBLIC_ROUTES } from "../../constants/publicroutes";

export default function Error403() {
  return (
    <>
      <IllustratedMessage height="50%" marginTop="size-300">
        <Unauthorized />

        <Content>
          Si vous ne trouvez pas la page recherché,
          <br />
          <Link>
            <RouterLink to={"../" + PUBLIC_ROUTES.contact}>vous pouvez faire une suggestion</RouterLink>
          </Link>
        </Content>
      </IllustratedMessage>
    </>
  );
}
