import { useState, useEffect, useMemo } from "react";
import { createApi } from "unsplash-js";

const accessKey = import.meta.env.VITE_UNSPLASH_ACCESS_KEY;

const unsplash = createApi({
  accessKey,
});

async function fetchPhotoByTheme(topic) {
  try {
    const result = await unsplash.search.getPhotos({
      query: topic,
      orientation: "landscape",
      orderBy: "relevant",
      page: 1,
      perPage: 1,
    });

    const data = result.response.results[0];

    if (!data) {
      throw new Error("No results found");
    }

    const photoUrl = `${data.links.download_location}?utm_source=Tramway-609736&utm_medium=referral`;
    const response = await fetch(`${photoUrl}&client_id=${accessKey}`);
    const downloadData = await response.json();

    return {
      photoURL: data.urls.small,
      photoAuthor: data.user.username,
      photoDownload: downloadData.url,
      photoPortfolio: data.user.links.html,
      photoThumbNailURL: data.urls.thumb,
    };
  } catch (error) {
    console.error("Erreur lors de la récupération des photos :", error);
    return {
      photoURL: null,
      photoAuthor: null,
      photoDownload: null,
      photoPortfolio: null,
      photoThumbNailURL: null,
    };
  }
}

function useFetchPhotos(topic) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [photosData, setPhotosData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const photoData = await fetchPhotoByTheme(topic);
        setPhotosData(photoData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [topic]);

  const memoizedData = useMemo(
    () => ({
      photosData,
      loading,
      error,
    }),
    [photosData, loading, error],
  );

  return memoizedData;
}

export default useFetchPhotos;
