import { useContext, useMemo } from "react";
import { ButtonGroup } from "@adobe/react-spectrum";
import UserSession from "../contexts/UserSession";
import useAuthenticatorAssuranceLevel from "../hooks/useAuthenticatorAssuranceLevel";
import UserChangeEmailButton from "./UserChangeEmailButton";
import UserChangePseudoButton from "./UserChangePseudoButton";
import UserMultiFactorAuthButton from "./UserMultiFactorAuthButton";
import UserDeleteAccountButton from "./UserDeleteAccountButton";
import UserDownloadPersonalDataButton from "./UserDownloadPersonalDataButton";

export default function LoginAndSecurity() {
  const { setNextLevelMFA, nextLevelMFA, session, sessionPseudo, sessionEmail } = useContext(UserSession);
  const { error, loading } = useAuthenticatorAssuranceLevel(setNextLevelMFA);

  const isMFA = useMemo(() => nextLevelMFA === "aal2", [nextLevelMFA]);

  return (
    <ButtonGroup width="size-2400" orientation="vertical" align="start" isDisabled={!session}>
      <UserChangeEmailButton sessionEmail={sessionEmail} />
      <UserChangePseudoButton sessionPseudo={sessionPseudo} />
      {!error && !loading && <UserMultiFactorAuthButton isMFA={isMFA} />}
      <UserDeleteAccountButton />
      <UserDownloadPersonalDataButton />
    </ButtonGroup>
  );
}
