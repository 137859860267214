import { memo } from "react";
import CircleCard from "../Circles/CircleCard";
import { circles } from "../../constants/circles";
import PrivateLayout from "../../components/Layout/PrivateLayout";

import { Flex } from "@adobe/react-spectrum";

const AllCircleCards = memo(() => {
  return (
    <PrivateLayout>
      <Flex marginY="size-100" justifyContent="space-evenly" direction="row" gap="size-300" wrap>
        {circles.map((circle) => (
          <CircleCard key={circle?.id} circle={circle} />
        ))}
      </Flex>
    </PrivateLayout>
  );
});

AllCircleCards.displayName = "AllCircleCards";
export default AllCircleCards;
