import { Well, Image, Text, Flex, Badge, Heading, View, Button, Avatar } from "@adobe/react-spectrum";
function SubFeatures({ isSmallScreen }) {
  return (
    <>
      <Flex
        marginY="size-100"
        direction={{ base: "column", S: "row", M: "row", L: "row" }}
        gap="size-200"
        justifyContent="space-between"
        alignItems="center"
      >
        <View maxWidth={{ base: "100%", S: "45%", M: "50%", L: "55%" }}>
          <Heading
            level={2}
            UNSAFE_style={{
              // textAlign: isSmallScreen ? "center" : "left",
              textDecoration: "underline",
              textDecorationStyle: "wavy",
              fontSize: "2rem",
            }}
          >
            État d'esprit
          </Heading>

          <Text>
            Tramway est fait pour les "Roots".Si vous cherchez une connexion authentique, vous êtes au bon endroit. Ce
            n’est pas un simple site de rencontre pour adultes de tous horizons, mais un tiers-lien où la nature, la
            durabilité et l’authenticité sont célébrées. Ici, on crée des souvenirs qui durent, dans un esprit de
            simplicité et de respect.
          </Text>
        </View>
        <Image
          maxWidth={{ base: "80%", S: "50%", M: "100%", L: "40%" }}
          alt="Un couple amoureux observe les étoiles en pleine nature, partageant un moment romantique lors d'un pique-nique sous le ciel étoilé."
          src="etat_esprit.png"
        />
      </Flex>

      <Flex
        marginY="size-100"
        direction={{ base: "column", S: "row", M: "row", L: "row" }}
        gap="size-200"
        justifyContent="space-between"
        alignItems="center"
      >
        <Image
          maxWidth={{ base: "80%", S: "50%", M: "100%", L: "40%" }}
          alt="Un couple amoureux handi-valide savoure un café matinal en pleine forêt, avec une tente installée en arrière-plan et des étoiles à peine visibles, partageant un moment romantique."
          src="handi_pmr.png"
        />
        <View maxWidth={{ base: "100%", S: "45%", M: "50%", L: "55%" }}>
          <Heading
            UNSAFE_style={{
              textDecoration: "underline",
              textDecorationStyle: "wavy",
              fontSize: "2rem",
            }}
            level={2}
          >
            Handicap
          </Heading>
          <Heading level={3}>Un site de rencontre ouvert au handicap</Heading>{" "}
          <Text
            UNSAFE_style={{
              textAlign: isSmallScreen ? "center" : "left",
            }}
          >
            Nous croyons que l’amour handi-valide est non seulement possible, mais aussi enrichissant. Tramway est une
            plateforme engagée, pensée pour les célibataires en quête d’authenticité et d’accessibilité. Ici, chacun
            peut se connecter, partager des moments uniques et construire des souvenirs durables, quelle que soit son
            histoire.
          </Text>
        </View>
      </Flex>
    </>
  );
}

export default SubFeatures;
