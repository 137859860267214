import { PUBLIC_ROUTES } from "./publicroutes";
import { publicPagesData } from "./pagesData";

// root
const hostname = "https://www.tramway.life";

// vitePrerender
const PUBLIC_ROUTES_ARRAY_vitePrerender = Object.values(PUBLIC_ROUTES);

// VitePluginSitemap
const dynamicRoutes = PUBLIC_ROUTES_ARRAY_vitePrerender.map((route) => `${route}`);

const dynamicRoutesWithSlash = PUBLIC_ROUTES_ARRAY_vitePrerender.filter(
  (route) => !(/\.(ovh||txt||xml)$/.test(route) || route === "/" || route.includes("*")),
).map((route) => (route.startsWith("/") ? route : `/${route}`));

// Page siteMap
const publicSiteMap = Array.from(publicPagesData, ([key, value], index) => ({
  id: index + 1,
  name: value.pageTitle,
  description: value.description,
  key,
}));

// ViteImageOptimizer
const DEFAULT_OPTIONS_ViteImageOptimizer = {
  test: /\.(jpe?g|png|gif|tiff|webp|svg|avif)$/i,
  exclude: undefined,
  include: undefined,
  includePublic: true,
  logStats: true,
  svg: {
    multipass: true,
    plugins: [
      {
        name: "preset-default",
        params: {
          overrides: {
            cleanupNumericValues: false,
            removeViewBox: false, // https://github.com/svg/svgo/issues/1128
          },
          cleanupIDs: {
            minify: false,
            remove: false,
          },
          convertPathData: false,
        },
      },
      "sortAttrs",
      {
        name: "addAttributesToSVGElement",
        params: {
          attributes: [{ xmlns: "http://www.w3.org/2000/svg" }],
        },
      },
    ],
  },
  png: {
    // https://sharp.pixelplumbing.com/api-output#png
    quality: 100,
  },
  jpeg: {
    // https://sharp.pixelplumbing.com/api-output#jpeg
    quality: 100,
  },
  jpg: {
    // https://sharp.pixelplumbing.com/api-output#jpeg
    quality: 100,
  },
  tiff: {
    // https://sharp.pixelplumbing.com/api-output#tiff
    quality: 100,
  },
  // gif does not support lossless compression
  // https://sharp.pixelplumbing.com/api-output#gif
  gif: {},
  webp: {
    // https://sharp.pixelplumbing.com/api-output#webp
    lossless: true,
  },
  avif: {
    // https://sharp.pixelplumbing.com/api-output#avif
    lossless: true,
  },
};

export {
  PUBLIC_ROUTES_ARRAY_vitePrerender,
  DEFAULT_OPTIONS_ViteImageOptimizer,
  dynamicRoutes,
  dynamicRoutesWithSlash,
  hostname,
  publicSiteMap,
};
