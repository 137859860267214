import { useState } from "react";

import NotificationList from "./NotificationList";
import useVibration from "../hooks/useVibration";

import {
  Flex,
  ActionButton,
  DialogTrigger,
  Dialog,
  Heading,
  Divider,
  Content,
  Tooltip,
  TooltipTrigger,
  View,
  IllustratedMessage,
} from "@adobe/react-spectrum";

import Bell from "@spectrum-icons/workflow/Bell";
import Events from "@spectrum-icons/workflow/Events";
// import { getLocalTimeZone, now } from "@internationalized/date";

function Notifications() {
  const { vibrate } = useVibration();
  let [hasSeeNotification, setHasSeeNotification] = useState(false);

  const handleNotificationStatus = () => {
    vibrate();
    setHasSeeNotification(true);
  };

  let notifications = null;
  // let notifications = [
  //   {
  //     id: "accueil",
  //     img: "./android-chrome-144x144.png",
  //     title: "Visite",
  //     time: now(getLocalTimeZone()),
  //     link: "../mentionslegales",
  //   },
  //   {
  //     id: "messages",
  //     img: "./android-chrome-144x144.png",
  //     title: "Message",
  //     time: now(getLocalTimeZone()),
  //     link: "../mentionslegales",
  //   },
  //   {
  //     id: "flash",
  //     img: "./android-chrome-144x144.png",
  //     title: "Flash",
  //     time: now(getLocalTimeZone()),
  //     link: "../mentionslegales",
  //   },
  // ];

  return (
    <DialogTrigger type="tray">
      <TooltipTrigger>
        <View colorVersion={6} backgroundColor={!hasSeeNotification && "purple-700"} borderRadius="regular">
          <ActionButton isQuiet aria-label="notifications" onPress={handleNotificationStatus}>
            <Bell aria-label="notifications" />
          </ActionButton>
        </View>
        <Tooltip>Notifications</Tooltip>
      </TooltipTrigger>
      <Dialog>
        <Heading>Notifications</Heading>

        <Divider />
        <Content>
          <Flex direction="column" gap={8}>
            {notifications ? (
              <NotificationList notifications={notifications} />
            ) : (
              <IllustratedMessage marginY="size-250">
                <Events size="S" />
                <Heading>Bienvenue</Heading>
                <Content>Tramway • courant alternatif ®</Content>
              </IllustratedMessage>
            )}
          </Flex>
        </Content>
      </Dialog>
    </DialogTrigger>
  );
}

export default Notifications;
