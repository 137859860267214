const SPECTRUM_LIGHT_COLOR_VALUES = [
  "gray-50",
  "gray-75",
  "gray-100",
  "gray-200",
  "gray-300",
  "gray-400",
  "gray-500",
  "gray-600",
  "gray-700",
  "gray-800",
  "gray-900",
  "red-100",
  "red-200",
  "red-300",
  "red-400",
  "red-500",
  "red-600",
  "red-700",
  "red-800",
  "red-900",
  "red-1000",
  "red-1100",
  "red-1200",
  "red-1300",
  "red-1400",
  "orange-100",
  "orange-200",
  "orange-300",
  "orange-400",
  "orange-500",
  "orange-600",
  "orange-700",
  "orange-800",
  "orange-900",
  "orange-1000",
  "orange-1100",
  "orange-1200",
  "orange-1300",
  "orange-1400",
  "yellow-100",
  "yellow-200",
  "yellow-300",
  "yellow-400",
  "yellow-500",
  "yellow-600",
  "yellow-700",
  "yellow-800",
  "yellow-900",
  "yellow-1000",
  "yellow-1100",
  "yellow-1200",
  "yellow-1300",
  "yellow-1400",
  "chartreuse-100",
  "chartreuse-200",
  "chartreuse-300",
  "chartreuse-400",
  "chartreuse-500",
  "chartreuse-600",
  "chartreuse-700",
  "chartreuse-800",
  "chartreuse-900",
  "chartreuse-1000",
];

export { SPECTRUM_LIGHT_COLOR_VALUES };
