import { memo } from "react";
import PropTypes from "prop-types";
import { View, Heading, Content, Text, Footer, Link, ContextualHelp } from "@adobe/react-spectrum";

const InfoHelp = memo(({ title, text, mention, photoDownload, picture, photoPortfolio, photoAuthor }) => (
  <View position="absolute" right="size-100" top="size-100">
    <ContextualHelp variant="info">
      <Heading>{title}</Heading>
      <Content>
        <Text>{text}</Text>
        {mention && (
          <Text>
            <Heading margin="size-50" level={6}>
              {mention}
            </Heading>
          </Text>
        )}
      </Content>
      <Footer>
        <Heading margin="size-10" level={6}>
          {photoDownload ? "Auteur de la photo : " : ""}{" "}
          <Link
            variant="secondary"
            href={`${photoPortfolio}?utm_source=Tramway-609736&utm_medium=referral`}
            download={!picture}
            target="_blank"
            rel="noopener noreferrer"
          >
            {photoAuthor}
          </Link>{" "}
          Crédit :{" "}
          <Link
            variant="secondary"
            href={picture ? "https://picsum.photos/" : "https://unsplash.com/?utm_source=609736&utm_medium=referral"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {picture ? "picsum.photos" : "Unsplash"}
          </Link>
        </Heading>
      </Footer>
    </ContextualHelp>
  </View>
));

InfoHelp.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  mention: PropTypes.string,
  photoDownload: PropTypes.string,
  picture: PropTypes.string,
  photoPortfolio: PropTypes.string,
  photoAuthor: PropTypes.string,
};

InfoHelp.displayName = "InfoHelp";
export default InfoHelp;
