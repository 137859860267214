import PropTypes from "prop-types";
import { useState, lazy, Suspense, memo } from "react";
import MFAHOC from "./MFAHOC";
import Loading from "./Loading";
import KeyClock from "@spectrum-icons/workflow/KeyClock";
import { Button, Text, DialogContainer, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

// ENRÔLER UNE MFA
const SetLazyMFADialog = memo(lazy(() => import("./SetMFADialog")));
const MFAHOCWrapper = MFAHOC(SetLazyMFADialog);

// DÉSACTIVER UNE MFA
import UnenrollMFAButton from "./UnenrollMFAButton";
const MFAHOCWrapperOff = MFAHOC(UnenrollMFAButton);

export default function UserMultiFactorAuthButton({ isMFA }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {!isMFA && (
        <>
          <Button
            id="fuck"
            width="100%"
            height="size-700"
            isDisabled={isMFA}
            variant="primary"
            style="outline"
            aria-label="Authentification multifacteur (MFA)"
            onPress={() => setOpen(true)}
          >
            <KeyClock />
            <Text>Authentification multifacteur (MFA)</Text>
          </Button>

          <DialogContainer onDismiss={() => setOpen(false)}>
            {isOpen && (
              <Suspense fallback={<Loading />}>
                <MFAHOCWrapper />
              </Suspense>
            )}
          </DialogContainer>
        </>
      )}

      {isMFA && (
        <>
          <TooltipTrigger showIcon variant="info" delay={0}>
            <MFAHOCWrapperOff />
            <Tooltip>
              La désactivation de la double authentification entraîne une nouvelle demande de connexion.
            </Tooltip>
          </TooltipTrigger>
        </>
      )}
    </>
  );
}

UserMultiFactorAuthButton.propTypes = {
  isMFA: PropTypes.bool.isRequired,
};
