import { useState } from "react";
import PropTypes from "prop-types";

import EditEmailDialog from "./EditEmailDialog";
import EmailRefresh from "@spectrum-icons/workflow/EmailRefresh";
import { Button, Text, DialogContainer } from "@adobe/react-spectrum";

export default function UserChangeEmailButton({ sessionEmail, ...props }) {
  let [isOpen, setOpen] = useState(false);
  return (
    <>
      <Button
        width="100%"
        height="size-700"
        variant="primary"
        aria-label="Editer mon email"
        style="outline"
        onPress={() => setOpen(true)}
      >
        <EmailRefresh />
        <Text>Editer mon email</Text>
      </Button>

      <DialogContainer onDismiss={() => setOpen(false)} {...props}>
        {isOpen && sessionEmail && <EditEmailDialog sessionEmail={sessionEmail} />}
      </DialogContainer>
    </>
  );
}
UserChangeEmailButton.propTypes = {
  sessionEmail: PropTypes.string,
  props: PropTypes.object,
};
