import { v4 as uuidv4 } from "uuid";

import SignIn from "../../components/SignIn";
import { Heading, Badge, Flex, View, Image } from "@adobe/react-spectrum";

export default function Connexion() {
  const nbrOfPhotos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 11, 12];

  return (
    <>
      <Flex
        justifyContent="center"
        direction={{ base: "column", S: "row", M: "row", L: "row" }}
        width="100%"
        gap="size-400"
        alignItems="start"
        alignContent="space-evenly"
      >
        <Heading level={3}>Ça fait plaisir de te revoir !</Heading>
        <Flex
          justifyContent="start"
          maxWidth={{
            base: "100%",
            S: "size-3600",
            M: "size-3600",
            L: "size-3600",
          }}
          direction="row"
          gap="size-100"
          wrap
        >
          <Flex direction="row" gap="size-100" wrap>
            <Badge variant="purple">Bouton d&apos;Or</Badge>
            <Badge variant="purple">Lune</Badge>
            <Badge variant="purple">Soleil</Badge>

            <Badge variant="purple">Je t&apos;aime… moi non plus</Badge>
            <Badge variant="purple">Cœur d&apos;artichaut</Badge>
          </Flex>
          <SignIn />
          <Flex marginBottom="size-1000" justifyContent="center" direction="row" gap="size-100" wrap>
            {nbrOfPhotos.map((pic) => (
              <View overflow="hidden" key={uuidv4()} width="size-800" height="size-1400" borderRadius="medium">
                <Image
                  slot="hero"
                  alt={`Une image aléatoire représentant la beauté de la nature ${"numéro : " + pic}`}
                  src={`https://picsum.photos/100/150.webp?random=${pic}&grayscale`}
                  objectFit="cover"
                />
              </View>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
