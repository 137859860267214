import { Well, Image, Text, Link, Flex, Heading, View, Button } from "@adobe/react-spectrum";

import FeaturedIn from "./FeaturedIn";
import SubFeatures from "./SubFeatures";
import FeatureOne from "./FeatureOne";
import ReassuranceItemPicto from "./ReassuranceItemPicto";
import useUnsplashPhoto from "../Hooks/useUnsplashPhoto";

function FeaturesBenefits({ isSmallScreen }) {
  const { photoUrl, portfolioUrl, name, altDescription } = useUnsplashPhoto("tmZcGf9ixPE");

  return (
    <View paddingStart="size-500" paddingEnd="size-500" paddingTop="size-500" paddingBottom="size-500">
      <FeaturedIn />
      <FeatureOne />


      <ReassuranceItemPicto />
      <SubFeatures isSmallScreen={isSmallScreen} />

      <Well maxWidth="100%">
        <Heading
          level={2}
          UNSAFE_style={{
            textDecoration: "underline",
            textDecorationStyle: "wavy",
            fontSize: "2rem",
          }}
        >
          Alternative Attitude
        </Heading>
        <Text>
          Que vous soyez maître composteur, randonneur, artiste ou yogi, ou que vous viviez sur une montagne sacrée,
          Tramway vous accueille dans un espace où les esprits libres se rencontrent. Ici, la créativité et la connexion
          avec la nature se rejoignent pour offrir une vie en harmonie avec l'Outdoor.
        </Text>

        <Heading level={3}>TRAMWAY — Le point de départ de l'aventure à deux.</Heading> <Button>Holistic trip</Button>
        <Button>Manifesto</Button>
        <Button>Handi & PMR yeah</Button>



<Flex width="300px" height="300px">
  <Image
     src="alternative_attitude.png"
    alt="Eiffel Tower at sunset"
    objectFit="cover" />
</Flex>

        <Text>TRAMWAY ne vendra pas votre adresse. <br />Désinscription très simple.</Text>

      </Well>
    </View>
  );
}

export default FeaturesBenefits;
