import { useState, memo, lazy, Suspense } from "react";

import Loading from "./Loading";

// ENROLL A MFA
import MFAHOC from "./MFAHOC";
const SetlazyDeleteAccountDialog = memo(lazy(() => import("./DeleteAccountDialog")));
const DeleteAccountHOCWrapper = MFAHOC(SetlazyDeleteAccountDialog);

import { Button, Text, DialogContainer } from "@adobe/react-spectrum";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";

export default function UserDeleteAccountButton() {
  let [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        width="100%"
        height="size-700"
        variant="primary"
        aria-label="Clôturer mon compte"
        style="outline"
        onPress={() => setOpen(true)}
      >
        <CloseCircle />
        <Text>Clôturer mon compte</Text>
      </Button>

      <DialogContainer onDismiss={() => setOpen(false)}>
        {isOpen && (
          <Suspense fallback={<Loading />}>
            <DeleteAccountHOCWrapper />
          </Suspense>
        )}
      </DialogContainer>
    </>
  );
}
