import { useState, useEffect } from "react";

import { Divider, Flex, View } from "@adobe/react-spectrum";

import UserToggleLightThemeButton from "../../components/UserToggleLightThemeButton";
import Header from "../../components/LandingPage/Header/Header";
import HeroSection from "../../components/LandingPage/HeroSection/HeroSection";
import FeaturesBenefits from "../../components/LandingPage/FeaturesBenefits/FeaturesBenefits";
import MoreSocialProof from "../../components/LandingPage/MoreSocialProof/MoreSocialProof";
import Footer from "../../components/PublicFooter";

export default function Landing() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return (
    <>
      <View colorVersion="6" backgroundColor="static-gray-100">
        <Flex direction="column" justfyContent="center">
          <Header />
          <HeroSection isSmallScreen={isSmallScreen} />
          <FeaturesBenefits isSmallScreen={isSmallScreen} />
          <MoreSocialProof isSmallScreen={isSmallScreen} />
          <Footer />
        </Flex>
      </View>

      <View
        zIndex="5"
        position="fixed"
        right="size-200"
        bottom="size-200"
        backgroundColor="gray-100"
        marginY="size-250"
        padding="size-10"
        borderRadius="medium"
      >
        <Flex direction="column" gap="size-100">
          <UserToggleLightThemeButton />
        </Flex>
      </View>
    </>
  );
}
