import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Form,
  Button,
  TextField,
  CheckboxGroup,
  Checkbox,
  Flex,
  Heading,
  Content,
  ContextualHelp,
  Text,
  Link,
  Footer,
  Well,
} from "@adobe/react-spectrum";
import { Turnstile } from "@marsidev/react-turnstile";
import More from "@spectrum-icons/workflow/More";

import InlineAlertForm from "./InlineAlertForm";
import TramFlashIcon from "./TramFlashIcon";
import { TERMS } from "../constants/terms";
import { FORMTYPES } from "../constants/formTypes";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import useTemporarilyLoading from "../hooks/useTemporarilyLoading";
import useSignInWithOTP from "../hooks/useSignInWithOTP";
import UserAvatar from "./MemberMedia/UserAvatar";

const SignUp = () => {
  const [captchaToken, setCaptchaToken] = useState();
  const sitekey = import.meta.env.VITE_CLOUDFLARE_TURNSTILE_KEY;

  const {
    loading,
    message,
    messageVariant,
    showMessageOutsideForm,
    email,
    isEmailValid,
    handleEmailChange,
    pseudo,
    isPseudoValid,
    handlePseudoChange,
    selected,
    handleCheckboxChange,
    handleSignInWithOTP,
    isDisabled,
    status,
  } = useSignInWithOTP(FORMTYPES.signUP, captchaToken);
  const { count, tooManyRequest, maxValue } = useTemporarilyLoading(status, 240);

  const handleCaptchaSuccess = (token) => setCaptchaToken(token);

  return (
    <>
      {showMessageOutsideForm && messageVariant === "positive" && (
        <InlineAlertForm
          messageVariant={messageVariant}
          message={message}
          tooManyRequest={tooManyRequest}
          count={count}
          maxValue={maxValue}
        />
      )}

      {!showMessageOutsideForm && (
        <Form marginY="size-0" name="inscription" isRequired necessityIndicator="icon">
          {message && messageVariant !== "positive" && (
            <InlineAlertForm
              messageVariant={messageVariant}
              message={message}
              tooManyRequest={tooManyRequest}
              count={count}
              maxValue={maxValue}
            />
          )}
          <UserAvatar position="relative" left="80%" bottom="5%" size={700} text={pseudo} />
          <TextField
            marginY="size-100"
            autoComplete="username"
            inputMode="text"
            label="Pseudo en minuscule"
            name="pseudo"
            type="text"
            validationState={isPseudoValid === null ? "" : isPseudoValid ? "valid" : "invalid"}
            value={pseudo}
            onChange={handlePseudoChange}
            aria-label="Un pseudo en lettres minuscules de 3 à 12 lettres"
            errorMessage="Un pseudo en lettres minuscules de 3 à 12 lettres"
          />

          <TextField
            autoComplete="email"
            inputMode="email"
            label="Email"
            name="email"
            type="email"
            validationState={isEmailValid === null ? "" : isEmailValid ? "valid" : "invalid"}
            value={email}
            onChange={handleEmailChange}
            aria-label="email"
            errorMessage="La forme de ton email doit être de type xxx@xxx.xxx"
          />

          <CheckboxGroup
            isRequired
            label="J'ai lu, j'ai compris et j'accepte : "
            value={selected}
            onChange={handleCheckboxChange}
            contextualHelp={<TermsContextualHelp />}
          >
            <Checkbox name={TERMS.CGV} value={TERMS.CGV} aria-label="Les CGV & les CGU">
              Les CGV & les CGU
            </Checkbox>
            <Checkbox name={TERMS.cookies} value={TERMS.cookies} aria-label="Les cookies de service">
              Les cookies de service
            </Checkbox>
            <Checkbox name={TERMS.RGPD} value={TERMS.RGPD} aria-label="La politique de confidentialité : RGPD">
              La politique de confidentialité
            </Checkbox>
          </CheckboxGroup>

          <Turnstile siteKey={sitekey} onSuccess={handleCaptchaSuccess} />

          <Flex alignItems="center" justifyContent="right" gap="size-200">
            <Link>
              <RouterLink to={`../${PUBLIC_ROUTES.connexion}`}>Tu as déjà un compte ?</RouterLink>
            </Link>

            <Button
              alignSelf="end"
              width="auto"
              marginY="size-200"
              variant="primary"
              style="outline"
              aria-label={loading ? "S'inscrire. En cours... Vérifie ta boite mail !" : "S'inscrire"}
              onPress={handleSignInWithOTP}
              isDisabled={isDisabled || loading || (tooManyRequest && count < maxValue)}
              isPending={loading}
            >
              {loading ? <More color="positive" size="L" /> : <TramFlashIcon color="MediumPurple" size="L" />}
              <Text>S&apos;inscrire</Text>
            </Button>
          </Flex>
        </Form>
      )}
    </>
  );
};

const TermsContextualHelp = () => (
  <ContextualHelp>
    <Heading>Ouverture de compte et Conformité aux Réglementations</Heading>
    <Content>
      <Well>
        La création de compte est possible sans recours à un système d&apos;identification tiers (Facebook, Google,
        Microsoft, Apple...) cependant, un email est nécessaire.
      </Well>
      <Well marginY="size-50">
        Pour finaliser la création d&apos;un compte, une confirmation de l&apos;email renseigné est requise.
      </Well>
      <Well marginY="size-50">
        La politique de confidentialité et de respect de la vie privée est accessible depuis toutes les pages du site.
      </Well>
      <Well marginY="size-50">La connexion à tous les services proposés est possible avec les mêmes identifiants.</Well>
      <Well marginY="size-50">
        Les comptes ou abonnements ouverts en ligne peuvent être fermés de manière aussi simple en utilisant le même
        moyen. Il est également possible de se déconnecter des espaces privés.
      </Well>
      <Well marginY="size-50">
        Il est impératif d&apos;être légalement majeur et en mesure de prendre des décisions en toute responsabilité,
        conformément à la loi.
      </Well>
      <Well marginY="size-50">
        En résumé : Ne pas utiliser tramway.life pour du contenu illégal ou pour adultes. Aidez-nous à Assurer la
        sécurité du Tramway en signalant tout contenu illégal ou inapproprié rencontré via la page contact. Utiliser
        uniquement tramway.life de manière légale, authentique et positive.
      </Well>
    </Content>
    <Footer>
      <Flex width="100%" marginY="size-100" justifyContent="center" wrap alignItems="center">
        <Link variant="secondary">
          <RouterLink to={`../${PUBLIC_ROUTES.mentionsLegales}`}>
            <Heading margin="size-50" level={6}>
              RGPD
            </Heading>
          </RouterLink>
        </Link>{" "}
        /
        <Link variant="secondary">
          <RouterLink to={`../${PUBLIC_ROUTES.CGV_CGU}`}>
            <Heading margin="size-50" level={6}>
              CGU & CGV
            </Heading>
          </RouterLink>
        </Link>{" "}
        /
        <Link variant="secondary">
          <RouterLink to={`../${PUBLIC_ROUTES.accessibilite}`}>
            <Heading margin="size-50" level={6}>
              ACCESSIBILITE
            </Heading>
          </RouterLink>
        </Link>
      </Flex>
    </Footer>
  </ContextualHelp>
);

export default SignUp;
