import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import useSecurityEmailVerification from "./useSecurityEmailVerification";

const useUpdateEmailToSupabase = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getMessage, setGetMessage] = useState("");
  const [getAlerte, setGetAlerte] = useState(false);
  const [getVariant, setGetVariant] = useState("");

  const { securityEmailMessage, securityEmailMessageVariant, securityEmailVerification } =
    useSecurityEmailVerification();

  useEffect(() => {
    setGetVariant(securityEmailMessageVariant);
    setGetMessage(securityEmailMessage);
  }, [securityEmailMessage, securityEmailMessageVariant]);

  const handleUpdateEmailToSupabase = async (email) => {
    setIsLoading(true);
    setGetAlerte(false);

    const isEmailVerificationSuccessful = email && (await securityEmailVerification(email));

    if (isEmailVerificationSuccessful) {
      try {
        setIsLoading(true);
        setGetVariant("positive");
        setGetMessage("Changement d'Adresse E-mail en Cours !");
        setGetAlerte(true);

        const { error } = await supabase.auth.updateUser({ email });

        if (error) {
          console.error(error);
          setIsLoading(false);
          setGetVariant("notice");
          setGetMessage(
            error.status === 422
              ? "Ooops, cette email est déjà pris !"
              : error.status === 429
              ? " Oops, une petite pause s'impose ! Trop de tentatives pour des raisons de sécurité reviens dans quelques minutes 🌟"
              : "Une erreur s'est produite. Nous faisons le maximum pour résoudre cela au plus vite !",
          );
          setGetAlerte(true);
        } else {
          await supabase.auth.refreshSession();
          setIsLoading(false);
          setGetVariant("positive");
          setGetMessage(
            "La mise à jour de ton adresse e-mail envoie un lien de confirmation à la fois à ton adresse e-mail actuelle et à la nouvelle.",
          );
          setGetAlerte(true);
        }
      } catch (error) {
        setIsLoading(false);
        setGetVariant("notice");
        setGetMessage("Une erreur s'est produite. Nous faisons le maximum pour résoudre cela au plus vite !");
        setGetAlerte(true);
      }
    } else {
      setIsLoading(false);
      setGetAlerte(true);
    }
  };

  return { handleUpdateEmailToSupabase, isLoading, getMessage, getAlerte, getVariant };
};

export default useUpdateEmailToSupabase;
