import { DialogContainer, AlertDialog } from "@adobe/react-spectrum";
import useSupabaseLogout from "../hooks/useSupabaseLogout";
import useUpdateIsActiveToSupabase from "../hooks/useUpdateIsActiveToSupabase";

export default function AccountReactivationConfirmationDialog() {
  const { logout } = useSupabaseLogout();
  const { handleUpdateIsActiveToSupabase } = useUpdateIsActiveToSupabase();

  let onPrimaryAction = async () => await handleUpdateIsActiveToSupabase(true);
  let onSecondaryAction = async () => await logout();

  return (
    <DialogContainer type="fullscreenTakeover">
      <AlertDialog
        autoFocusButton="primary"
        title="C'est l'heure de réactiver ton compte Tramway !"
        variant="confirmation"
        primaryActionLabel="Composter mon ticket"
        secondaryActionLabel="À la prochaine Station"
        onPrimaryAction={onPrimaryAction}
        onSecondaryAction={onSecondaryAction}
      >
        🚋 Youpi ! Tu es sur le point de redonner vie à ton compte Tramway. En compostant ton ticket, tu réactiveras
        toutes les fonctionnalités géniales. Sinon, ton compte sera comme un tramway arrivé au Terminus, prêt à rentrer
        à l&apos;entrepôt.
      </AlertDialog>
    </DialogContainer>
  );
}
