import { Image, Flex, View } from "@adobe/react-spectrum";

function VisualExamples() {
  return (
    <View maxWidth={{ base: "100%", S: "100%", M: "100%", L: "40%" }} justifyContent="center">
      <Flex direction="column" gap="size-100" alignItems="center" justifyContent="center">
        <Image
          width="100%"
          src="landing_page_koople.png"
          alt="Un couple amoureux observe les étoiles en pleine nature, partageant un moment romantique lors d'un pique-nique sous le ciel étoilé."
        />
        <span
          style={{
            fontFamily: "monospace",
            color: "DarkGoldenRod",
            fontWeight: "bold",
          }}
          title="attitude alternative"
          lang="en"
        >
          Alternative <br />
          with attitude <span title="emoji : un coeur de cupidon et une feuille d'olivier">💘 🌿</span>
        </span>
      </Flex>
    </View>
  );
}

export default VisualExamples;
