import { useState, lazy, Suspense } from "react";
import Loading from "./Loading";
import Download from "@spectrum-icons/workflow/Download";
import { Button, Text, DialogContainer } from "@adobe/react-spectrum";

const GenerateAndViewPersonalDataLazyDialog = lazy(() => import("./GenerateAndViewPersonalDataDialog"));

export default function UserDownloadPersonalDataButton() {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openDialog = () => {
    setIsLoading(true);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        width="100%"
        height="size-700"
        variant="primary"
        aria-label="Télécharger Mes Données personnelles en PDF"
        style="outline"
        isPending={isLoading}
        onPress={openDialog}
      >
        <Download />
        <Text>Télécharger mes données</Text>
      </Button>
      <DialogContainer onDismiss={closeDialog}>
        {isOpen && (
          <Suspense fallback={<Loading />}>
            <GenerateAndViewPersonalDataLazyDialog />
          </Suspense>
        )}
      </DialogContainer>
    </>
  );
}
