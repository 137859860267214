import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FAQ_TRAMWAY } from "../../constants/faq";
import HandleOnSelectionChange from "../../config/HandleSelectionChange";

import { ListBox, Section, Item, Text, Avatar } from "@adobe/react-spectrum";

export default function Faq() {
  let [selected, setSelected] = useState(new Set());
  const navigate = useNavigate();

  return (
    <>
      <ListBox
        aria-label="Questions"
        items={FAQ_TRAMWAY}
        selectedKeys={selected}
        selectionMode="single"
        onSelectionChange={(e) => {
          setSelected(e);
          HandleOnSelectionChange(e, navigate);
        }}
        width="100%"
      >
        {(section) => (
          <Section aria-label="Questions fréquentes" key={section.title} items={section.children} title={section.title}>
            {(item) => (
              <Item key={item.id} textValue={item?.title} aria-label={item?.link && "Naviguer vers : " + item.link}>
                {item?.link ? item?.icon : <Avatar key={item.id} src="./android-chrome-96x96.png" />}
                <Text>{item?.title}</Text>
                <Text slot="description">{item?.text}</Text>
              </Item>
            )}
          </Section>
        )}
      </ListBox>
    </>
  );
}
