import { Suspense } from "react";
import { Tabs, Item, TabPanels, View, TabList, Text } from "@adobe/react-spectrum";
import CreditCard from "@spectrum-icons/workflow/CreditCard";
import Experience from "@spectrum-icons/workflow/Experience";
import Search from "@spectrum-icons/workflow/Search";
import Loading from "../../components/Loading";

import Glossaire from "../../components/Glossaire";
import Cgv from "../../components/Cgv";
import Cgu from "../../components/Cgu";

const tabs = Object.freeze([
  {
    key: "CGV",
    icon: <CreditCard />,
    label: "CGV",
    component: Cgv,
    text: "CGV : Conditions Générales de Vente du Tramway. Tramway • Courant Alternatif, www.tramway.life est une petite plateforme de rencontre destinée aux personnes majeures souhaitant initier des relations virtuelles à des fins amicales ou sentimentales, basées sur un intérêt commun pour le développement durable et la nature. La plateforme est accessible via son site internet et son application progressive, qui peut être installée automatiquement sur les navigateurs basés sur Chromium, comme Google Chrome. Les rencontres sur Tramway sont strictement personnelles, récréatives et non commerciales/professionnelles.",
    textValue: "Conditions Générales de Vente.",
  },
  {
    key: "CGU",
    icon: <Experience />,
    label: "CGU",
    component: Cgu,
    text: "CGU : Conditions Générales d'utilisation du Tramway. Tramway • Courant Alternatif, www.tramway.life est une petite plateforme de rencontre destinée aux personnes majeures souhaitant initier des relations virtuelles à des fins amicales ou sentimentales, basées sur un intérêt commun pour le développement durable et la nature. La plateforme est accessible via son site internet et son application progressive, qui peut être installée automatiquement sur les navigateurs basés sur Chromium, comme Google Chrome. Les rencontres sur Tramway sont strictement personnelles, récréatives et non commerciales/professionnelles.",
    textValue: "Conditions Générales d'utilisation",
  },
  {
    key: "GLOSSAIRE",
    icon: <Search />,
    label: "GLOSSAIRE",
    component: Glossaire,
    text: "GLOSSAIRE : Un lexique adapté aux membres explique le vocabulaire sectoriel ou technique.",
    textValue: "GLOSSAIRE : Un lexique adapté aux membres explique le vocabulaire sectoriel ou technique.",
  },
]);

export default function CGV_CGU() {
  return (
    <Tabs
      alignSelf="center"
      justifySelf="center"
      disallowEmptySelection
      defaultSelectedKey="CGV"
      density="regular"
      aria-label="Conditions Générales d'utilisation et de vente Prestations de services du Tramway"
    >
      <TabList>
        {tabs.map((tab) => (
          <Item key={tab.key} textValue={tab.textValue}>
            {tab.icon}
            <Text>{tab.label}</Text>
          </Item>
        ))}
      </TabList>

      <TabPanels marginTop="size-200">
        {tabs.map((tab) => (
          <Item key={tab.key} textValue={tab.textValue}>
            {tab?.text}
            <View marginY="size-200">
              <Suspense fallback={<Loading />}>
                <tab.component />
              </Suspense>
            </View>
          </Item>
        ))}
      </TabPanels>
    </Tabs>
  );
}
