import { Well, Header, Text, Flex, Badge, Heading, View, Button, Avatar } from "@adobe/react-spectrum";
import SignIn from "@spectrum-icons/workflow/Login";
import CTAWithReassurance from "../HeroSection/CTAWithReassurance";

function MoreSocialProof() {
  return (
    <View paddingStart="size-500" paddingEnd="size-500" paddingTop="size-500" paddingBottom="size-500">
      {/* StatsOfHowManyUsers */}
      {/* Testimonials */}
      <Heading level={2}>Contact</Heading>
      <Text>
        On papote ? Vous avez des questions ? Laissez-nous votre message, nous nous efforçons de répondre dans les deux
        jours ouvrés.{" "}
      </Text>
      <Heading level={2}>Nos partenaires</Heading>
      <Heading level={2}>Faq&apos;s</Heading>
      <Heading level={2}>S&apos;inscrire</Heading>
      <CTAWithReassurance />
      <Heading level={2}>Actu</Heading>
    </View>
  );
}

export default MoreSocialProof;
