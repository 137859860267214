import { lazy, useContext, memo } from "react";
import UserSession from "../../contexts/UserSession";

// MFA HOC form
import MFAHOC from "../../components/MFAHOC";
const SetlazyMFAForm = memo(lazy(() => import("../../components/FormMFA")));
const MFAHOCWrapper = MFAHOC(SetlazyMFAForm);

// REACTIVATE ACCOUNT
import AccountReactivationConfirmationDialog from "../../components/AccountReactivationConfirmationDialog";

import { IllustratedMessage, Heading, View, Flex, Content, ContextualHelp } from "@adobe/react-spectrum";
import Unauthorized from "@spectrum-icons/illustrations/Unauthorized";

function PrivatePage() {
  const { sessionIsActive } = useContext(UserSession);

  return (
    <>
      {!sessionIsActive && <AccountReactivationConfirmationDialog />}

      {sessionIsActive && (
        <Flex direction="column" gap="size-100" alignItems="center" marginTop="size-200" marginBottom="size-600">
          <View maxWidth="size-4600" borderWidth="thin" borderColor="dark" borderRadius="medium" padding="size-250">
            <IllustratedMessage>
              <Unauthorized />
              <Heading>Station Porte des étoiles</Heading>
              <Content>
                Double authentification
                <ContextualHelp>
                  <Heading>Besoin d&apos;aide ?</Heading>
                  <Content>
                    Il s&apos;agit du code aléatoire à 6 chiffres généré par ton application d&apos;authentification,
                    par exemple FreeOTP+ pour Android.
                  </Content>
                </ContextualHelp>
              </Content>
            </IllustratedMessage>
            <MFAHOCWrapper />
          </View>
        </Flex>
      )}
    </>
  );
}

export default PrivatePage;
