import { useContext } from "react";
import { Navigate } from "react-router-dom";

import UserSession from "../contexts/UserSession";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import { PRIVATE_ROUTES } from "../constants/privateroutes";

import useLazyLoadWithLayout from "../hooks/useLazyLoadWithLayout";

import Landing from "../pages/landing/Landing";
import Error404 from "../pages/public/Error404";
import Error403 from "../pages/public/Error403";
import Connexion from "../pages/public/Connexion";
import CreerCompte from "../pages/public/CreerCompte";
import Manifesto from "../pages/public/Manifesto";
import Avis from "../pages/public/Avis";
import Feed from "../pages/public/Feed";
import Contact from "../pages/public/Contact";
import Aide from "../pages/public/Aide";
import MentionsLegales from "../pages/public/MentionsLegales";
import Accessibilite from "../pages/public/Accessibilite";
import SiteMap from "../pages/public/SiteMap";
import Faq from "../pages/public/Faq";
import CGV_CGU from "../pages/public/CGV_CGU";

function GetPublicRoutes() {
  const Error404Lazy = useLazyLoadWithLayout(Error404);
  const Error403Lazy = useLazyLoadWithLayout(Error403);
  const ConnexionLazy = useLazyLoadWithLayout(Connexion);
  const CreerCompteLazy = useLazyLoadWithLayout(CreerCompte);
  const ManifestoLazy = useLazyLoadWithLayout(Manifesto);
  const AvisLazy = useLazyLoadWithLayout(Avis);
  const FeedLazy = useLazyLoadWithLayout(Feed);
  const ContactLazy = useLazyLoadWithLayout(Contact);
  const AideLazy = useLazyLoadWithLayout(Aide);
  const MentionsLegalesLazy = useLazyLoadWithLayout(MentionsLegales);
  const AccessibiliteLazy = useLazyLoadWithLayout(Accessibilite);
  const SiteMapLazy = useLazyLoadWithLayout(SiteMap);
  const FaqLazy = useLazyLoadWithLayout(Faq);
  const CGV_CGULazy = useLazyLoadWithLayout(CGV_CGU);

  const { sessionRole } = useContext(UserSession);

  return [
    {
      path: PUBLIC_ROUTES.index,
      element: (
        <>{sessionRole === "authenticated" ? <Navigate to={`../private/${PRIVATE_ROUTES.profile}`} /> : <Landing />}</>
      ),
    },
    {
      path: PUBLIC_ROUTES.error404,
      element: Error404Lazy,
    },
    {
      path: PUBLIC_ROUTES.error403,
      element: Error403Lazy,
    },

    {
      path: PUBLIC_ROUTES.connexion,
      element: sessionRole === "authenticated" ? <Navigate to={PUBLIC_ROUTES.index} replace /> : ConnexionLazy,
    },
    {
      path: PUBLIC_ROUTES.creerCompte,
      element: sessionRole === "authenticated" ? <Navigate to={PUBLIC_ROUTES.index} replace /> : CreerCompteLazy,
    },
    {
      path: PUBLIC_ROUTES.manifesto,
      element: ManifestoLazy,
    },
    {
      path: PUBLIC_ROUTES.avis,
      element: AvisLazy,
    },
    {
      path: PUBLIC_ROUTES.feed,
      element: FeedLazy,
    },
    {
      path: PUBLIC_ROUTES.contact,
      element: ContactLazy,
    },
    {
      path: PUBLIC_ROUTES.aide,
      element: AideLazy,
    },
    {
      path: PUBLIC_ROUTES.mentionsLegales,
      element: MentionsLegalesLazy,
    },
    {
      path: PUBLIC_ROUTES.accessibilite,
      element: AccessibiliteLazy,
    },
    {
      path: PUBLIC_ROUTES.sitemap,
      element: SiteMapLazy,
    },
    {
      path: PUBLIC_ROUTES.FAQ,
      element: FaqLazy,
    },
    {
      path: PUBLIC_ROUTES.CGV_CGU,
      element: CGV_CGULazy,
    },
  ];
}

export default GetPublicRoutes;
