import { useState } from "react";
import { supabase } from "../supabaseClient";

const useUpdatePseudoToSupabase = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alerte, setAlerte] = useState(false);
  const [variant, setVariant] = useState("");

  const handleUpdatePseudoToSupabase = async (pseudo) => {
    setAlerte(false);

    try {
      setIsLoading(true);
      setVariant("positive");
      setMessage("En cours...");
      setAlerte(true);

      const { error } = await supabase.auth.updateUser({
        data: {
          name: pseudo,
          terms: undefined,
        },
      });

      if (error) {
        console.error(error);

        setIsLoading(false);
        setVariant("notice");
        setMessage(
          error.status === 500
            ? "Renseigne un nouveau pseudo en minuscule, de 3 à 12 lettres maximum."
            : "Une erreur s'est produite. On fait le maximum pour résoudre ça au plus vite !",
        );
        setAlerte(true);
      } else {
        await supabase.auth.refreshSession();
        setIsLoading(false);
        setVariant("positive");
        setMessage("Et voilà !");
        setAlerte(true);
      }
    } catch (error) {
      console.error(error);

      setIsLoading(false);
      setVariant("notice");
      setMessage("Une erreur s'est produite. On fait le maximum pour résoudre ça au plus vite !");
      setAlerte(true);
    }
  };

  return { handleUpdatePseudoToSupabase, isLoading, message, alerte, variant };
};

export default useUpdatePseudoToSupabase;
