import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserSession from "../contexts/UserSession";
import { PRIVATE_ROUTES } from "../constants/privateroutes";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import { ActionButton, Text } from "@adobe/react-spectrum";
import TramFlashIcon from "./TramFlashIcon";

function TramwayIcon() {
  const navigate = useNavigate();
  const { sessionRole } = useContext(UserSession);

  return (
    <ActionButton
      isQuiet
      onPress={() =>
        navigate(sessionRole === "authenticated" ? `../private/${PRIVATE_ROUTES.accueil}` : PUBLIC_ROUTES.index)
      }
      width="auto"
      variant="secondary"
      aria-label="Accès rapide à la page d'accueil du Tramway ou de votre espace privé si vous êtes connecté"
    >
      <TramFlashIcon aria-label="Une étincelle, un éclair de lumière !" size="L" color="DarkGoldenRod" />

      <Text UNSAFE_className="logo" marginX="size-50" marginY="size-10">
        Tramway
      </Text>
    </ActionButton>
  );
}

export default TramwayIcon;
