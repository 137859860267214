import PropTypes from "prop-types";
import { View } from "@adobe/react-spectrum";
import { useHover } from "react-aria";

import InfoHelp from "./InfoHelp";
import HoverLink from "./HoverLink";
import Thumbnail from "./Thumbnail";
import CardBody from "./CardBody";
import LoadingInCircle from "./LoadingInCircle";
import useFetchPhotos from "./circlesHooks/useFetchPhotos";

function CircleCard({ circle }) {
  const { title, text, topic, mention, picture } = circle;

  // Fetch photos regardless, but handle the condition within the hook
  const { photosData, loading } = useFetchPhotos(picture ? null : topic);

  // Destructure photos data
  const { photoAuthor = "", photoURL = "", photoDownload = "", photoPortfolio = "" } = photosData || {};

  // Handle hover state
  const { hoverProps, isHovered } = useHover({
    onHoverStart: () => {},
    onHoverEnd: () => {},
  });

  return (
    <View
      overflow="hidden"
      width={{ base: "90%", S: "47%", M: "30%", L: "45%", XL: "30%" }}
      height="size-2600"
      borderRadius="large"
      position="relative"
    >
      <InfoHelp
        title={title}
        text={text}
        mention={mention}
        photoDownload={photoDownload}
        picture={picture}
        photoPortfolio={photoPortfolio}
        photoAuthor={photoAuthor}
      />

      <div {...hoverProps}>
        <HoverLink picture={picture} photoPortfolio={photoPortfolio} photoAuthor={photoAuthor} isHovered={isHovered} />

        {loading ? (
          <LoadingInCircle />
        ) : (
          <Thumbnail photoAuthor={photoAuthor} title={title} picture={picture} photoURL={photoURL} />
        )}
      </div>

      <CardBody title={title} />
    </View>
  );
}

CircleCard.propTypes = {
  circle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    emoji: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    mention: PropTypes.string,
    picture: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

CircleCard.displayName = "CircleCard";

export default CircleCard;
