import {
  Heading,
  Content,
  Footer,
  Well,
  LabeledValue,
  ContextualHelp,
  IllustratedMessage,
} from "@adobe/react-spectrum";
import PrivateLayout from "../../components/Layout/PrivateLayout";

import IllustratedFlash from "@spectrum-icons/workflow/FlashOff";

export default function Flash() {
  return (
    <>
      <PrivateLayout>
        <IllustratedMessage maxWidth="100%" margin="size-400">
          <IllustratedFlash size="L" aria-label="courrier" />
          <Heading>
            Y&apos;a pas de Flash, hélas ! <br /> C&apos;est là qu&apos;est l&apos;os !
          </Heading>

          <ContextualHelp variant="help">
            <Heading>
              En attendant un peu de culture... <span title="Emoji d'une carotte.">🥕</span>
            </Heading>
            <Content>
              Un chapitre fort intéressant sur les artichauts.
              <Well marginY="size-100">
                &quot;Pour sûr, les artichauts sont de volages amoureux : point ne se &quot;fixent&quot; et ils adorent
                &quot;jouer&quot;. On dirait volontiers qu’ils ont un &quot;coeur d’artichaut ! De semis ou de bouture,
                la reproduction de l’artichaut est une aventure.&quot;
              </Well>
            </Content>
            <Footer>
              <LabeledValue label="Selon Mr. Claude Foury, auteur." value="Histoire de Légumes. INRA" />
            </Footer>
          </ContextualHelp>

          <Content>
            Tramway • courant alternatif <span title="Emoji : Marque déposée"></span>®
          </Content>
        </IllustratedMessage>
      </PrivateLayout>
    </>
  );
}
