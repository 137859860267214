import { Tweet } from "react-tweet";
import { useProvider } from "@adobe/react-spectrum";
import { Flex, View } from "@adobe/react-spectrum";
const TwitterTimeline = () => {
  const { colorScheme } = useProvider();

  return (
    <Flex direction="column" gap="size-100" alignItems="center">
      <View borderWidth="thin" borderColor="dark" borderRadius="medium" data-theme={colorScheme}>
        <Tweet id="1777614929505263686" />
      </View>
    </Flex>
  );
};

export default TwitterTimeline;
