import PropTypes from "prop-types";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PUBLIC_ROUTES } from "../constants/publicroutes";

export default function ProtectedRoute({ isAuthenticated, children, currentLevelMFA, nextLevelMFA, sessionIsActive }) {
  const isMFACheckRequired = useMemo(() => {
    return nextLevelMFA === "aal2" && nextLevelMFA !== currentLevelMFA;
  }, [nextLevelMFA, currentLevelMFA]);

  switch (true) {
    case !isAuthenticated:
      return <Navigate to={`../${PUBLIC_ROUTES.connexion}`} replace />;

    case isMFACheckRequired || !sessionIsActive:
      return children;

    default:
      return <Outlet />;
  }
}

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.string,
  children: PropTypes.node.isRequired,
  currentLevelMFA: PropTypes.string,
  nextLevelMFA: PropTypes.string,
  sessionIsActive: PropTypes.bool,
};
