import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

export const useUserSession = () => {
  const [session, setSession] = useState();
  const [sessionPseudo, setSessionPseudo] = useState();
  const [sessionEmail, setSessionEmail] = useState();
  const [sessionTerms, setSessionTerms] = useState();
  const [sessionIsActive, setSessionIsActive] = useState();
  const [sessionRole, setSessionRole] = useState();
  const [sessionEvent, setSessionEvent] = useState();
  const [sessionLastSignIn, setSessionLastSignIn] = useState();
  const [sessionConfirmed, setSessionConfirmed] = useState();
  const [sessionExpires, setSessionExpires] = useState();
  const [currentLevelMFA, setCurrentLevelMFA] = useState();
  const [nextLevelMFA, setNextLevelMFA] = useState();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setSessionPseudo(`${session?.user?.user_metadata?.name}`);
      setSessionTerms(session?.user?.user_metadata?.terms);
      setSessionIsActive(session?.user?.user_metadata?.is_active);
      setSessionRole(session?.user?.role);
      setSessionEmail(session?.user?.email);
      setSessionLastSignIn(session?.user?.last_sign_in_at);
      setSessionConfirmed(session?.user?.confirmed_at);
      setSessionExpires(session?.expires_in);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setSessionPseudo(`${session?.user?.user_metadata?.name}`);
      setSessionTerms(session?.user?.user_metadata?.terms);
      setSessionIsActive(session?.user?.user_metadata?.is_active);
      setSessionRole(session?.user?.role);
      setSessionEmail(session?.user?.email);
      setSessionLastSignIn(session?.user?.last_sign_in_at);
      setSessionConfirmed(session?.user?.confirmed_at);
      setSessionExpires(session?.user?.expires_in);
      setSessionEvent(_event);
    });

    supabase.auth.mfa.getAuthenticatorAssuranceLevel().then(({ data: { currentLevel, nextLevel } }) => {
      setCurrentLevelMFA(currentLevel);
      setNextLevelMFA(nextLevel);
    });
  }, []);

  return {
    session,
    sessionPseudo,
    sessionTerms,
    sessionIsActive,
    sessionRole,
    sessionEmail,
    sessionLastSignIn,
    sessionConfirmed,
    sessionExpires,
    sessionEvent,
    currentLevelMFA,
    nextLevelMFA,
    setNextLevelMFA,
    setCurrentLevelMFA,
  };
};
