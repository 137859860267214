import { v4 as uuidv4 } from "uuid";

import { Heading, Badge, Flex, View, Text, Image } from "@adobe/react-spectrum";
import Checkmark from "@spectrum-icons/workflow/Checkmark";

import SignUp from "../../components/SignUp";

export default function CreerCompte() {
  const nbrOfPhotos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 11, 12];
  return (
    <>
      <Flex
        justifyContent="center"
        direction={{ base: "column", S: "row", M: "row", L: "row" }}
        width="100%"
        gap="size-400"
        alignItems="start"
        alignContent="space-evenly"
      >
        <Heading level={3}>Ça fait plaisir une belle rencontre !</Heading>
        <Flex
          justifyContent="start"
          maxWidth={{
            base: "100%",
            S: "size-3600",
            M: "size-3600",
            L: "size-3600",
          }}
          direction="row"
          gap="size-100"
          wrap
        >
          <Flex direction="column" gap={8}>
            <Badge variant="indigo">
              <Checkmark aria-label="Inscris toi" />
              <Text>Inscris toi</Text>
            </Badge>
            <Badge variant="indigo">
              <Checkmark aria-label="Définis ton profil" />
              <Text>Définis ton profil</Text>
            </Badge>
            <Badge variant="indigo">
              <Checkmark aria-label="Rencontre" />
              <Text>Rencontre</Text>
            </Badge>
          </Flex>
          <SignUp />

          <Flex justifyContent="center" direction="row" gap="size-100" wrap marginBottom="size-1000">
            {nbrOfPhotos.map((pic) => (
              <View overflow="hidden" key={uuidv4()} width="size-800" height="size-1400" borderRadius="medium">
                <Image
                  slot="hero"
                  alt={`Une image aléatoire représentant la beauté de la nature ${"numéro : " + pic}`}
                  src={`https://picsum.photos/100/150.webp?random=${pic}`}
                  objectFit="cover"
                />
              </View>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
