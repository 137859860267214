import { Image, Text, Link, Flex, Heading, View } from "@adobe/react-spectrum";
import useUnsplashPhoto from "../Hooks/useUnsplashPhoto.jsx";

function FeatureOne({ isSmallScreen }) {
  const { photoUrl, portfolioUrl, name, altDescription } = useUnsplashPhoto("tmZcGf9ixPE");

  return (
    <View>

    <Flex
      marginY="size-1000"
      direction={{ base: "column", S: "column", M: "column", L: "row" }}
      gap="size-200"
      justifyContent="space-between"
      alignItems="center"
    >

<Image
        maxHeight="size-3000"
        width={{ base: "100%", S: "60%", M: "60%", L: "40%" }}
        alt={altDescription}
        src={photoUrl}
        objectFit="cover"
      />
      <View maxWidth={{ base: "100%", S: "80%", M: "80%", L: "55%" }}>
        <Heading

          UNSAFE_style={{
            textAlign: isSmallScreen ? "center" : "left",

            fontSize: "2rem",
          }}
          marginY="size-50"
          level={2}
        >
          La beauté se rencontre <br />
          dans la simplicité
        </Heading>
        <Text
          UNSAFE_style={{
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          Prêt à tomber amoureux ou à créer des liens authentiques ? Tramway vous offre un espace très simple,
          efficace, pour aller à l'essentiel. Le point de départ de l'aventure à deux. Ici, les rencontres sont en
          phase avec vos valeurs de vie responsable et durable, au cœur de la nature, de l'Outdoor et des modes de
          vie alternatifs.{" "}




        </Text>
        <Link
    target="blank"
    rel="noopener noreferrer"


    variant="secondary"
    // isQuiet
    href={portfolioUrl}
  >
    {"Crédit photo : "+name}
  </Link>
      </View>
    </Flex>
  </View>
  );
}

export default FeatureOne;
