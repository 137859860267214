import { lazy, Suspense, memo, useState } from "react";

import Loading from "../../components/Loading";
import ProfilTabs from "../../components/MemberData/ProfilTabs";
import RealTimeCustomerProfile from "../../components/RealTimeCustomerProfile";
import PrivateLayout from "../../components/Layout/PrivateLayout";

const ReviewsDialog = lazy(() => import("../../components/ReviewsDialog"));
const SetlazyPushNotifications = memo(lazy(() => import("../../components/PushNotifications")));
const SetlazySignOutSupabaseDialog = memo(lazy(() => import("../../components/SignOutSupabaseDialog")));

import LogOutUser from "@spectrum-icons/workflow/LogOut";
import Preview from "@spectrum-icons/workflow/TestProfile";
import ThumbUp from "@spectrum-icons/workflow/ThumbUp";
import PushNotification from "@spectrum-icons/workflow/PushNotification";

import {
  ActionButton,
  Flex,
  DialogTrigger,
  Text,
  Tooltip,
  TooltipTrigger,
  DialogContainer,
} from "@adobe/react-spectrum";

export default function Profil() {
  let [isOpen, setOpen] = useState(false);

  return (
    <PrivateLayout>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="size-100">
          {/* Prévisualiser ton profil */}
          <DialogTrigger type="tray">
            <TooltipTrigger>
              <ActionButton aria-label="Prévisualiser ton profil" marginY="size-200">
                <Preview />
                <Text>Prévisualiser</Text>
              </ActionButton>
              <Tooltip showIcon="true">Prévisualiser ton profil</Tooltip>
            </TooltipTrigger>
            <RealTimeCustomerProfile />
          </DialogTrigger>

          {/* ShoppingCart */}
          <DialogTrigger type="tray">
            <TooltipTrigger>
              <ActionButton aria-label="Laisser un avis" marginY="size-200">
                <ThumbUp />
              </ActionButton>
              <Tooltip showIcon="true">Laisser un avis</Tooltip>
            </TooltipTrigger>
            <Suspense fallback={<Loading />}>
              <ReviewsDialog />
            </Suspense>
          </DialogTrigger>

          {/* Gérer mes Alertes */}
          <DialogTrigger type="tray">
            <TooltipTrigger>
              <ActionButton aria-label="Gérer mes notifications" marginY="size-200">
                <PushNotification />
              </ActionButton>
              <Tooltip showIcon="true">Gérer mes notifications</Tooltip>
            </TooltipTrigger>
            <Suspense fallback={<Loading />}>
              <SetlazyPushNotifications />
            </Suspense>
          </DialogTrigger>
        </Flex>

        {/* DECONNEXION */}
        <TooltipTrigger>
          <ActionButton
            marginY="size-200"
            alignSelf="end"
            variant="secondary"
            aria-label="Déconnexion"
            isQuiet
            onPress={() => setOpen(true)}
          >
            <LogOutUser />
          </ActionButton>
          <Tooltip showIcon="true">Se déconnecter</Tooltip>
        </TooltipTrigger>

        <DialogContainer onDismiss={() => setOpen(false)}>
          {isOpen && (
            <Suspense fallback={<Loading />}>
              <SetlazySignOutSupabaseDialog />
            </Suspense>
          )}
        </DialogContainer>
      </Flex>

      <ProfilTabs />
    </PrivateLayout>
  );
}
