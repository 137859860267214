import LoginAndSecurity from "../../components/LoginAndSecurity";
import PrivateLayout from "../../components/Layout/PrivateLayout";

import { Flex } from "@adobe/react-spectrum";

export default function Compte() {
  return (
    <PrivateLayout noBorders={true}>
      <Flex justifyContent="center">
        <LoginAndSecurity />
      </Flex>
    </PrivateLayout>
  );
}
