import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Form, Button, TextField, Flex, Text, Link } from "@adobe/react-spectrum";
import { Turnstile } from "@marsidev/react-turnstile";
import More from "@spectrum-icons/workflow/More";
import InlineAlertForm from "./InlineAlertForm";
import TramFlashIcon from "./TramFlashIcon";
import { FORMTYPES } from "../constants/formTypes";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import useTemporarilyLoading from "../hooks/useTemporarilyLoading";
import useSignInWithOTP from "../hooks/useSignInWithOTP";

const SignIn = () => {
  const [captchaToken, setCaptchaToken] = useState();
  const sitekey = import.meta.env.VITE_CLOUDFLARE_TURNSTILE_KEY;

  const {
    loading,
    message,
    messageVariant,
    showMessageOutsideForm,
    email,
    isEmailValid,
    handleEmailChange,
    handleSignInWithOTP,
    isDisabled,
    status,
  } = useSignInWithOTP(FORMTYPES.signIn, captchaToken);

  const { count, tooManyRequest, maxValue } = useTemporarilyLoading(status, 240);

  const handleCaptchaSuccess = (token) => setCaptchaToken(token);

  return (
    <>
      {showMessageOutsideForm && messageVariant === "positive" && (
        <InlineAlertForm
          messageVariant={messageVariant}
          message={message}
          tooManyRequest={tooManyRequest}
          count={count}
          maxValue={maxValue}
        />
      )}

      {!showMessageOutsideForm && (
        <Form name="connexion" isRequired necessityIndicator="icon">
          {message && (
            <InlineAlertForm
              messageVariant={messageVariant}
              message={message}
              tooManyRequest={tooManyRequest}
              count={count}
              maxValue={maxValue}
            />
          )}

          <TextField
            autoComplete="email"
            inputMode="email"
            label="Email"
            name="email"
            type="email"
            validationState={isEmailValid === null ? "" : isEmailValid ? "valid" : "invalid"}
            value={email}
            onChange={handleEmailChange}
            aria-label="email"
          />

          <Turnstile siteKey={sitekey} onSuccess={handleCaptchaSuccess} />

          <Flex alignItems="center" justifyContent="right" gap="size-300" marginY="size-200">
            <Link>
              <RouterLink to={`../${PUBLIC_ROUTES.creerCompte}`}>Ouvrir un compte ?</RouterLink>
            </Link>
            <Button
              marginY="size-200"
              alignSelf="end"
              width="auto"
              variant="primary"
              style="outline"
              aria-label={loading ? "S'identifier. En cours... Vérifie ta boite mail !" : "S'identifier"}
              onPress={handleSignInWithOTP}
              isDisabled={isDisabled || loading || (tooManyRequest && count < maxValue)}
              isPending={loading}
            >
              {loading ? <More color="positive" size="L" /> : <TramFlashIcon color="MediumPurple" size="L" />}
              <Text>S&apos;identifier</Text>
            </Button>
          </Flex>
        </Form>
      )}
    </>
  );
};

export default SignIn;
