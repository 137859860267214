import { lazy, Suspense, memo } from "react";

import Loading from "../Loading";
const LazyProfilPictures = memo(lazy(() => import("../MemberMedia/ProfilPictures")));
const LazyUserProfilDescription = memo(lazy(() => import("./ProfilDescription")));
const LazyUserProfilDetails = memo(lazy(() => import("./ProfilDetails")));

import { Tabs, Item, TabPanels, TabList, Text, ContextualHelp, Content, Heading } from "@adobe/react-spectrum";

import Photo from "@spectrum-icons/workflow/ImageAdd";
import Description from "@spectrum-icons/workflow/AnnotatePen";
import Details from "@spectrum-icons/workflow/NewsAdd";

function HomeTabs() {
  return (
    <>
      <Tabs
        alignSelf="center"
        justifySelf="center"
        disallowEmptySelection="true"
        defaultSelectedKey="1"
        density="regular"
        aria-label="Les membres du Tramway sont tous ici"
      >
        <TabList>
          <Item key="1" textValue="Photos">
            <Photo size="S" aria-label="Photos" />
            <Text>Photos</Text>
          </Item>
          <Item key="2" textValue="Bio">
            <Description size="S" aria-label="Bio" />
            <Text>Bio</Text>
          </Item>

          <Item key="4" textValue="Profil">
            <Details size="S" aria-label="Profil" />
            <Text>Profil</Text>
          </Item>
        </TabList>

        <TabPanels marginTop="size-200" marginX="size-0">
          <Item key="1" textValue="Modifier ma gallerie">
            Modifier ma gallerie
            <ContextualHelp variant="info">
              <Heading>Gallerie</Heading>
              <Content>
                <Text>Ajouter des photos depuis mon appareil au format JPG, PNG ou WEBP.</Text>
              </Content>
            </ContextualHelp>
            <LazyProfilPictures />
          </Item>

          <Item key="2" textValue="Apporte une Bio attrayante à ton profil.">
            Aide les autres à mieux te connaître en leur parlant de ta vie et des choses que tu aimes.
            <Suspense fallback={<Loading />}>
              <LazyUserProfilDescription />
            </Suspense>
          </Item>

          <Item key="4" textValue="Ce qui te rends unique.">
            Ce qui te rends unique. <span title="emoji : couronne, étincelles">👑✨</span>
            <Suspense fallback={<Loading />}>
              <LazyUserProfilDetails />
            </Suspense>
          </Item>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default HomeTabs;
