import { useState } from "react";
import PropTypes from "prop-types";

import EditPseudoDialog from "./EditPseudoDialog";
import EditUser from "@spectrum-icons/workflow/UserEdit";
import { Button, Text, DialogContainer } from "@adobe/react-spectrum";

export default function UserChangePseudoButton({ sessionPseudo, ...props }) {
  let [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        width="100%"
        height="size-700"
        variant="primary"
        aria-label="Editer mon pseudo"
        style="outline"
        onPress={() => setOpen(true)}
      >
        <EditUser />
        <Text>Editer mon pseudo</Text>
      </Button>

      <DialogContainer onDismiss={() => setOpen(false)} {...props}>
        {isOpen && sessionPseudo && <EditPseudoDialog sessionPseudo={sessionPseudo} />}
      </DialogContainer>
    </>
  );
}
UserChangePseudoButton.propTypes = {
  sessionPseudo: PropTypes.string,
  props: PropTypes.object,
};
