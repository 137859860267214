import PropTypes from "prop-types";
import {
  useDialogContainer,
  Heading,
  Form,
  Button,
  TextField,
  Dialog,
  Divider,
  Content,
  ButtonGroup,
  Header,
  Text,
  Flex,
  InlineAlert,
} from "@adobe/react-spectrum";
import Edit from "@spectrum-icons/workflow/Edit";
import Close from "@spectrum-icons/workflow/Close";
import useUpdatePseudoToSupabase from "../hooks/useUpdatePseudoToSupabase";
import usePseudoValidation from "../hooks/usePseudoValidation";
import LoremPicsum from "./LoremPicsum";

const EditPseudoDialog = ({ sessionPseudo }) => {
  const dialog = useDialogContainer();
  const { handleUpdatePseudoToSupabase, isLoading, message, alerte, variant } = useUpdatePseudoToSupabase();
  const { pseudo, isPseudoValid, handlePseudoChange } = usePseudoValidation();

  return (
    <Dialog size="S">
      <LoremPicsum size={600} id={25} />
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Edit aria-label="Stylo pour écrire un nouveau pseudo" size="S" color="" />
          <Text>Pseudo</Text>
        </Flex>
      </Heading>
      <Header>{sessionPseudo}</Header>
      <Divider />
      <Content>
        <Form>
          {alerte && (
            <InlineAlert variant={variant}>
              <Heading>Information de validation</Heading>
              <Content>{message}</Content>
            </InlineAlert>
          )}
          <TextField
            autoComplete="given-name"
            inputMode="text"
            label="Modifier mon pseudo"
            name="pseudo"
            type="text"
            validationState={isPseudoValid === null ? "" : isPseudoValid ? "valid" : "invalid"}
            value={pseudo}
            description="Ce n'est pas votre nom, ou un code. Ce pseudo sera visible par les utilisateurs du Tramway"
            onChange={handlePseudoChange}
            aria-label="Un pseudo de 3 à 12 lettres"
            errorMessage="Un pseudo de 3 à 12 lettres"
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button
          variant="secondary"
          style="fill"
          isDisabled={isLoading}
          onPress={dialog.dismiss}
          aria-label="Fermer la fenêtre"
          width="size-600"
        >
          <Close />
        </Button>
        <Button
          isPending={isLoading}
          isDisabled={isLoading || !isPseudoValid || pseudo === sessionPseudo}
          aria-label="Modifier"
          variant="primary"
          onPress={() => handleUpdatePseudoToSupabase(pseudo.trim().toLowerCase())}
        >
          Modifier
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

EditPseudoDialog.propTypes = {
  sessionPseudo: PropTypes.string.isRequired,
};

export default EditPseudoDialog;
