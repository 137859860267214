import PropTypes from "prop-types";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LabeledValue, Text, ListBox, Item, Avatar } from "@adobe/react-spectrum";

import { getLocalTimeZone, now } from "@internationalized/date";

function NotificationList({ notifications }) {
  const navigate = useNavigate();
  let [notificationId, setNotificationId] = useState(new Set());

  const handlePressNotification = (selected) => {
    setNotificationId(selected);
    navigate("../private/" + selected.currentKey);
  };

  const formatDate = (date) => {
    let localTime = now(getLocalTimeZone());
    let pushedTime = date;

    const isMinute = localTime.minute - pushedTime.minute;
    const isHour = localTime.hour - pushedTime.hour;
    const isDay = localTime.day - pushedTime.day;
    const isMonth = localTime.month - pushedTime.month;
    const isYear = localTime.year - pushedTime.year;

    switch (true) {
      case !isYear && !isMonth && !isDay && !isHour && !isMinute:
        return "à l'instant";
      case !isYear && !isMonth && !isDay && !isHour & (isMinute < 60):
        return `{${isMinute > 1 ? isMinute + " minutes" : isMinute + " minute"}}`;
      case !isYear && !isMonth && !isDay && isHour < 24:
        return `${isHour > 1 ? isHour + " heures" : isHour + " heure"}`;
      case !isYear && !isMonth && isDay < 31:
        return `${isDay > 1 ? isDay + " jours" : isDay + " jour"}`;
      case !isYear && isMonth < 12:
        return `${isMonth} mois`;
      case isYear >= 1:
        return `plus d'une ${isYear > 1 ? isYear + " années" : isYear + " année"}`;
      default:
        return `${isDay} jours`;
    }
  };

  return (
    <ListBox
      selectionMode="single"
      aria-label={`Liste des notifications - Naviguer vers ${notificationId?.currentKey ?? "la notification choisie"}`}
      items={notifications}
      onSelectionChange={(selected) => handlePressNotification(selected)}
    >
      {(item) => (
        <Item textValue={item?.title}>
          <Avatar src={item?.img} alt="Tramway" />
          <Text>{item?.title}</Text>
          <Text slot="description">
            <LabeledValue labelPosition="side" labelAlign="end" label="depuis :" value={formatDate(item?.time)} />
          </Text>
        </Item>
      )}
    </ListBox>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.object,
};

export default NotificationList;
