import PropTypes from "prop-types";
import { useState } from "react";
import { Heading, LabeledValue, ToggleButton, Flex, View } from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import Checkmark from "@spectrum-icons/workflow/Checkmark";
import FullScreenMapBtn from "../GeoCode/FullScreenMapBtn";

const CardBody = ({ title }) => {
  const [isSelected, setSelected] = useState(false);

  return (
    <View
      borderBottomColor="dark"
      borderBottomWidth="thin"
      borderBottomStartRadius="large"
      borderBottomEndRadius="large"
      borderTopStartRadius="xsmall"
      borderTopEndRadius="xsmall"
      colorVersion={6}
    >
      <Flex alignItems="center" minWidth="100%" marginY="size-50">
        <Flex direction="column" width="75%" marginX="size-100">
          <Heading level={3} margin="size-0">
            {title}
          </Heading>
          <LabeledValue label="Membres" value={0} labelPosition="side" labelAlign="end" />
        </Flex>
        <Flex minWidth="25%" alignItems="center" gap="size-100" justifyContent="right" marginEnd="size-100">
          <FullScreenMapBtn />
          <ToggleButton
            isSelected={isSelected}
            onChange={() => setSelected(!isSelected)}
            aria-label={isSelected ? "Partir de ce cercle" : "Rejoindre le cercle"}
          >
            {isSelected ? <Checkmark /> : <Add />}
          </ToggleButton>
        </Flex>
      </Flex>
    </View>
  );
};

CardBody.propTypes = {
  title: PropTypes.string.isRequired,
};

CardBody.displayName = "CardBody";
export default CardBody;
