import { Flex, View } from "@adobe/react-spectrum";

import ValueProposition from "./ValueProposition";
import VisualExemples from "./VisualExamples";
import CTAWithReassurance from "./CTAWithReassurance";
import Subtitle from "./Subtitle";
import SocialProof from "./SocialProof";

function HeroSection({ isSmallScreen }) {
  return (
    <View
      maxWidth="100%"
      paddingStart="size-500"
      paddingEnd="size-500"
      paddingTop={{ base: "size-100", S: "size-500", M: "size-500", L: "size-500" }}
      paddingBottom="size-500"
    >
      <Flex
        width="100%"
        gap="size-100"
        direction={{ base: "column", S: "column", M: "column", L: "row" }}
        alignItems={{ base: "center", S: "center", M: "center", L: "start" }}
        justifyContent={{ base: "center", S: "center", M: "center", L: "space-between" }}
      >
        <View maxWidth={{ base: "100%", S: "100%", M: "100%", L: "55%" }}>
          <Flex
            direction="column"
            gap="size-100"
            alignItems={{ base: "center", S: "center", M: "center", L: "start" }}
            justifyContent={{ base: "center", S: "center", M: "center", L: "start" }}
          >
            <ValueProposition isSmallScreen={isSmallScreen} />
            <Subtitle isSmallScreen={isSmallScreen} />
            <CTAWithReassurance />
            <SocialProof />
          </Flex>
        </View>

        <VisualExemples />
      </Flex>
    </View>
  );
}

export default HeroSection;
